.relative {
  position: relative;
}

.aistrategy-page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 35px 7%;
  flex-wrap: wrap;
  align-items: center;
  background: #f3f6ff;
  letter-spacing: 0.42px;
  position: relative;
  font-family: "Poppins-Regular", Helvetica;
}

.aistrategy-page-container-responsive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 14px 10px;
  flex-wrap: wrap;
  align-items: flex-start;
  background: #fff;
  letter-spacing: 0.42px;
  position: relative;
  font-family: "Poppins-Regular", Helvetica;
}

.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88%;
}

.aistrategy-page-horizontal-card {
  display: flex;
  border: 1px solid #ccc;
  padding: 15px;
  margin-top: 20px;
  border-radius: 3.31px;
  background: #ffffff;
  width: 100%;
  height: 190px;
  flex-shrink: 0;
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;

  .card-heading {
    font-size: 22px;
  }

  img {
    min-width: 17.2%;
    height: 112px;
  }

  .new-badge {
    min-width: 40px;
    max-width: 92.4px;
    height: 23.609px;
    font-size: 12.1px;
  }

  .risk-badge {
    min-width: 65px;
    max-width: 92.4px;
    height: 23.609px;
    font-size: 11px;
  }

  .card-desc {
    font-size: 16px;
    max-width: 90%;
    min-width: 80%;
  }
}

.aistrategy-page-horizontal-card-responsive {
  display: flex;
  border: 1px solid #ccc;
  padding: 15px;
  margin-top: 20px;
  border-radius: 3.31px;
  background: #f3f6ff;
  font-family: "Poppins-Regular", Helvetica;
  width: auto;
  flex-direction: column;
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .aistrategy-page-horizontal-card {
    width: 100%;
  }

  .left-half {
    margin: 0;
    width: 100%;
    margin-bottom: 20px;
  }

  .right-half {
    margin: 0;
  }
}

.aistrategy-page-horizontal-card:hover {
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
}

.aistrategy-page-middle-section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.aistrategy-page-middle-section-responsive {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.single-aistrategy-page-middle-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
}

.single-aistrategy-page-middle-section-responsive {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.aistrategy-page-middle-section-responsive>.trending-box {
  border: 1px solid #eee;
}

.trending-box {
  border: 2px solid #ccc;
  margin: 20px;
  margin-top: 40px;
  position: relative;
  width: 90%;
  max-width: 832px;
  /* height: 66px; */
  font-weight: 400;

  p {
    padding: 10px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.7);
    margin-left: 3.5%;
  }
}

.trending-box-special {
  margin-left: 0px;
  margin-bottom: 35px;
}

.trending-box-title {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -11px;
  left: 3%;
  background-color: #f3f6ff;
  padding: 0 10px;
  font-weight: 400;
}

.trending-box-title-responsive {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -11px;
  left: 3%;
  background-color: white;
  padding: 0 10px;
  font-weight: 400;
}

.trending-box-responsive p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.06em;
  color: rgba(0, 0, 0, 0.7);
  margin: 0px;
  padding: 16px 11px 7px 35px;
}

.page-content-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.heading-section {
  width: 90%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  left: 0;
  margin-bottom: 28px;

  .overview-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 33px;
    font-size: 20px;
    font-style: normal;
    font-weight: 350;
    letter-spacing: 0.6px;
    border-bottom: solid #2747dd 2px;
    width: fit-content;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .overview-heading-underline {
    border: solid 0.5px #b2b2b2b2;
    width: 70%;
  }
}

.heading-section-responsive {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  left: 0;
  margin-bottom: 9px;
  margin-top: 20px;

  .overview-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0px !important;
    border-bottom: solid #2747dd 2px;
    width: fit-content;
    margin-left: 7px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.06em;
    color: #000000;
    padding: 0px !important;
  }
  
  .pb-13 {
    padding-bottom: 12px !important;
  }

  .overview-heading-underline {
    border: solid 0.5px #b2b2b2b2;
    width: 90%;
  }
}

.content-section {
  display: flex;
  justify-content: center;
  width: 90%;
}

.content-section-responsive {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  width: 100%;
}

.first-half-responsive,
.second-half-responsive {
  width: 100%;
}

.first-half-responsive .overview-body-responsive {
  width: 100%;
  margin-top: 21px;
}

.first-half-responsive .overview-body-responsive .overview-content {
  margin: 17px 21px;
}

.first-half-responsive .overview-body-responsive .overview-content .overview-content-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #000000;
}

.first-half-responsive .overview-body-responsive .overview-content .overview-info {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.03em;
  color: rgba(0, 0, 0, 0.7);
  margin: 0px;
  margin-top: 12px;
}

.first-half-responsive .overview-body-responsive .overview-content .overview-dropdown-qna {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.03em;
  color: rgba(0, 0, 0, 0.7);
  padding: 0px;
  margin-top: 18px;
}

.first-half {
  border: none;
  border-right: solid #b2b2b2b2 0.9px;
  margin-right: 31px;
  width: 100%;
  padding-right: 15px;
}

.first-half-responsive {
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overview-body {
  display: flex;
  justify-content: flex-start;
  border-radius: 3px;
  border: 1px solid #000;
  width: 98%;
  background: #fff;
  flex-wrap: wrap;
  left: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  height: 99%;
}

.overview-body-responsive {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 3px;
  width: 96%;
  flex-wrap: wrap;
  left: 0;
  background: #f3f6ff;
}

.overview-content {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 29.5px;
  letter-spacing: 0.48px;
  margin: 25px;
}

.overview-content-heading {
  font-size: 20px;
}

.overview-info {
  color: #000000b2;
}

.overview-dropdown-qna {
  color: #000000b2;
}

.overview-dropdown-qna {
  display: flex;
  flex-direction: column;
  padding: 10px;

  .overview-question {
    display: flex;
    align-items: center;
    margin: 0;
    cursor: pointer;
  }

  .overview-answer {
    margin: 0;
    padding: 0 !important;
    white-space: pre-wrap;
  }
}

.second-half {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.second-half-responsive {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  border-radius: 3px;
  gap: 50px;
  width: 100%;
}

.second-half-responsive>.strategy-subscribe-card {
  padding: 0px;
  padding-top: 25px;
  padding-bottom: 21px;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.second-half-responsive>.strategy-subscribe-card>.strategy-subscribe-card-heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.03em;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 17px;
}

.second-half-responsive>.strategy-subscribe-card>.strategy-subscribe-card-amount {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.03em;
  color: #000000;
  margin-bottom: 19.21px;
}

.second-half-responsive>.strategy-subscribe-card>.strategy-subscribe-card-heading,
.second-half-responsive>.strategy-subscribe-card>.strategy-subscribe-card-amount {
  width: auto;
}

.second-half-responsive>.strategy-subscribe-card,
.second-half-responsive>.info-card-responsive {
  background: #f3f6ff;
}

.second-half-responsive>.strategy-subscribe-card>.subscribe-social-share-section {
  width: 100%;
  padding-left: 42px;
  box-sizing: border-box;
}

.second-half-responsive>.strategy-subscribe-card>.subscribe-social-share-section>article {
  margin: 0px;
}

.second-half-responsive>.strategy-subscribe-card>.subscribe-social-share-section>.strategy-subscribe-card-social-icons {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-top: 21px;
  cursor:'pointer';
}

.second-half-responsive>.strategy-subscribe-card>.subscribe-social-share-section>.strategy-subscribe-card-social-icons>img {
  width: 26px;
  height: auto;
  cursor:'pointer';
}

.strategy-subscribe-card {
  width: 294px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-top: 15px;
  padding-left: 14px;
  padding-right: 30px;
  padding-bottom: 21px;
  /* height: 210px; */
  background: #fff;

  .strategy-subscribe-card-heading {
    width: 100%;
    letter-spacing: 0.48px;
    padding: 0;
    margin: 0;
    margin-bottom: 15px;
  }

  .strategy-subscribe-card-amount {
    font-size: 20px;
    justify-content: flex-start;
    width: 100%;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.03em;
    text-align: left;
    margin-bottom: 18px;
  }
}

.strategy-subscribe-card-plans {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #2747dd;
  margin-bottom: 25px;
  margin-left: 5px;
  gap: 12px;
  cursor: pointer;

  .arrow-icon {
    color: #2747dd;
  }
}

.subscribe-now-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #2747dd;
  color: #ffffff;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.03em;
  text-align: left;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 43px;
  padding-right: 40px;
  border-radius: 2.96px;
  margin: 0px;
  margin-bottom: 16px;
  margin-left: 5px;
}

.subscribe-social-share-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  left: 50px;
  width: 100%;
  font-family: Poppins;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.03em;
  text-align: left;
}

.subscribe-social-share-section article {
  color: #000000b2;
  margin: 0;
  padding: 0;
  margin-bottom: 12px;
}

.strategy-subscribe-card-social-icons {
  display: flex;
  cursor:'pointer';

  .strategy-subscribe-card-social-icon {
    width: 15.52px;
    height: 15.52px;
    margin-right: 16.47px;
  }
}

.info-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 294px;
  height: 288px;
  background: #fff;
  margin-bottom: 10px;
  margin-top: 37px;
  border-radius: 2.5px;
  padding: 0px 10px;
  gap: 24px;
  box-sizing: border-box;
}

.info-card-responsive {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 250px;
  background: #fff;
  margin-bottom: 10px;
  margin-top: 20px;
  border-radius: 2.5px;
  gap: 24px;
}

.info-card-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 22px;
}

.info-card-icon {
  width: 52px;
  height: 52px;
  top: 861px;
  left: 1001px;
  gap: 0px;
  /* opacity: 0px; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #2747dd;
  margin-right: 10px;
  background-color: #ffe48480;
  margin: 0px;
  box-shadow: 0px 4px 4px 0px #00000040;
  cursor: pointer;
}

.info-card-content {
  margin: 0px;
  padding: 0px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 6px;
  max-width: 165px;
  flex: 1;
}

.info-card-heading {
  display: flex;
  color: #2747dd;
  font-size: 16px;
  padding: 0px;
  margin: 0px;
  letter-spacing: 0.48px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-align: left;
  cursor: pointer;
}

.info-card-desc {
  font-size: 11px;
  font-family: Poppins;
  font-size: 11px;
  font-weight: 400;
  line-height: 17.5px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #000000b2;
}

/* subscribe pop up css */

.subscribe-popup-card {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-card-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 3.14px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 577.245px;
  height: 298px;

  article {
    font-size: 20.839px;
    font-weight: 500;
    letter-spacing: 0.625px;
  }
}

.popup-card-row {
  display: flex;
  height: 48px;
  width: 300px;
  align-items: center;
  justify-content: space-evenly;
  border: solid 1px;
  padding: 3px 0px;
  background-color: #fff;
  border-radius: 3.126px;

  article {
    display: flex;
    font-size: 17px;
    align-content: center;
    font-weight: 400;
  }

  input {
    border: none;
    border-radius: 5px;
    height: 55%;
    background: #f5f5f5;
    text-align: center;
  }

  input::placeholder {
    align-content: center;
    font-size: 14.587px;
  }
}

.popup-card-terms-conditions {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  width: 82%;
  font-weight: 300;
}

.popup-card-button-row {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 70%;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 10px;
    background: #2747dd;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    width: 154.21px;
    height: 36.469px;
    font-size: 16.671px;
    font-weight: 500;
    letter-spacing: 0.5px;
  }

  .popup-close-btn {
    color: #2747dd;
    background-color: #fff;
    border: solid;
    letter-spacing: 0.5px;
  }
}

.strategyMetrics {
  background-color: #f3f6ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 0 9rem; */
  padding: 0 7.5%;
}

.graphContainer {
  background-color: #fff;
  padding: 4px 10px;
  margin-bottom: 3rem;
  width: 90%;
  overflow: scroll;
}

.small-dot {
  width: 7px;
  height: 7px;
  position: absolute;
  right: -4px;
  bottom: -4px;
  background: rgba(178, 178, 178, 0.698);
  border-radius: 50%;
  z-index: 1;
}

.small-dot-responsive {
  width: 5px;
  height: 5px;
  position: absolute;
  right: -3px;
  bottom: -3px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  z-index: 1;
}

.section-responsive-line {
  display: none;
}

@media only screen and (max-width: 500px) {
  .strategy-pnl-items {
    padding: 16px 14px !important;
  }

  .section-responsive-line {
    display: block;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    height: 1px;
    position: relative;
  }

  .section-responsive-line>span {
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .result-summary-responsive .section-responsive-line:first-child>span {
    left: 0;
  }

  .result-summary-responsive .section-responsive-line:nth-child(2)>span {
    right: 0;
  }
}

@media only screen and (max-width: 500px) {
  .strategyMetrics {
    background: #ffffff;
    box-sizing: border-box;
    padding: 14px 10px;
  }

  .graphContainer {
    background: #f3f6ff;
    border-radius: 6.25407px;
    width: 100%;
    box-sizing: border-box;
  }
}

.specialGraphContainer {
  overflow: hidden;
}

@media only screen and (max-width: 1100px) {
  .yearly-calendar {
    transform: scale(1) !important;
  }

  .specialGraphContainer .trading-calendar div:nth-child(3) {
    align-items: flex-start !important;
  }

  .specialGraphContainer {
    overflow: scroll;
  }

  .specialGraphContainer .total-pnl {
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 500px) {
  .specialGraphContainer {
    overflow: hidden;
  }

  .specialGraphContainer .total-pnl {
    margin-left: 0px !important;
    flex-direction: column;
    align-items: flex-start !important;
    gap: 26px !important;
  }

  .special-wrapper {
    width: calc(100vw - 50px);
    overflow-x: scroll;
  }

  .specialGraphContainer .trading-calendar>div:nth-child(2)>div:first-child {
    display: none !important;
  }

  .specialGraphContainer .year-selector>div>button {
    padding: 0px !important;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 11px !important;
    line-height: 16px !important;
    letter-spacing: 0.06em !important;
    color: rgba(0, 0, 0, 0.7) !important;
  }

  .specialGraphContainer .trading-calendar {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .specialGraphContainer .year-selector>div .button-selected {
    padding: 2px 9px 0px 11px !important;
  }

  .specialGraphContainer .year-selector>div {
    padding: 10px 14px 9.43px 14px !important;
  }
}

.special-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.broker-page-details {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f6ff;
}

.horizontal-line {
  width: 100%;
  margin-top: -10px;
  margin-bottom: 10px;
  background: rgba(178, 178, 178, 0.7);
}

.broker-profile-body {
  background: #fff;
  border: 1px solid #ccc;
  width: 100%;
  padding: 15px 7px;
  text-align: center;
  border-radius: 5px;
}

.broker-profile-card {
  display: flex;
  justify-content: space-between;
}

.broker-partner-section {
  display: flex;
  align-items: center;
}

.broker-logo {
  width: 100px;
  height: 31px;
  object-fit: contain;
  margin-right: -29px;
  margin-left: -15px;
}

.connect-btn-body {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  margin-right: 20px;
}

.check-icon {
  margin-bottom: -5px;
  margin-right: 3px;
  color: #2747dd;
  font-size: 10px;
}

.btn-text {
  color: rgb(22, 22, 22);
  cursor: pointer;
  margin-left: 5px;
  margin-right: 8px;
}

@media only screen and (max-width: 800px) {
  .broker-profile-title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #000000;
  }

  .broker-logo {
    height: 23.83px;
  }

  .broker-profile-body {
    padding: 5px 0px;
  }

  .connect-btn-body {
    gap: 2.36px;
    align-items: center;
    justify-content: center;
    margin: 0px;
  }

  .connect-btn-body>svg {
    width: 16px;
  }

  .connect-btn-body .btn-text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.03em;
    color: #000000;
    margin-left: 0px;
    margin-right: 0px;
  }

  .check-icon svg {
    width: 15px;
    height: 15px;
    margin: 0px;
  }

  /* 13.64px */
}

form {
  margin-top: 20px;
  margin: 10px;
  padding: 30px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.42px;
  margin-bottom: 20px;
}

form label {
  display: block;
  padding: 10px;
  text-align: left;
  width: 40%;
}

form input {
  width: 80%;
  padding: 15px;
  margin-top: 7px;
  border: 1px solid rgba(178, 178, 178, 0.7);
  border-radius: 5px;
}

.totp {
  width: 50%;
}

.form-row {
  display: flex;
  justify-content: space-between;
}

label {
  width: 48%;
}

.broker-save-btn {
  padding: 10px 30px;
  color: white;
  border: none;
  cursor: pointer;
  float: left;
  margin-left: 2%;
  border-radius: 2.963px;
  background: #2747dd;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.6px;
}

@media only screen and (max-width: 500px) {
  .broker-save-btn {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16.7241px;
    line-height: 25px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    padding-top: 6.11px;
    padding-left: 26.24px;
    padding-bottom: 5.53px;
    padding-right: 27.76px;
    margin-bottom: 22.23px;
  }

  .broker-page-details input {
    margin: 0px;
    width: 210px;
    height: 29.24px;
    padding: 0px 5px;
    margin-top: 10px;
  }

  .broker-page-details .form-row {
    display: flex;
    flex-direction: column;
  }
}

.form-input-error-message {
  color: red;
  margin-left: 10px;
}
.terms-and-conditions-image-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 306px;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.03em;
    text-align: left;
    color: #ffffff;
    text-shadow: 0px 4px 4px #00000040;
    background: url("../../assets/images/imgtxtbanner.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.terms-and-conditions-content-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    box-sizing: border-box;
    background: #f3f6ff;
}

.terms-and-conditions-content {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 47px;
    gap: 27px;
    padding-left: 124px;
    padding-right: 209px;
}

.terms-and-conditions-content-title {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.03em;
    text-align: left;
    color: #252525;
}

.terms-and-conditions-content-description {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.03em;
    text-align: left;
    color: #000000b2;
}

.terms-and-conditions-content>.terms-and-conditions-content-description:not(:nth-child(2)) {
    margin-top: 28px;
}

.terms-and-conditions-notice {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    margin-top: 80px;
}

.terms-and-conditions-notice-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.03em;
    text-align: left;
    margin-bottom: 77px;
}

.terms-and-conditions-notice-content svg {
    margin-right: 23px;
}

.terms-and-conditions-t,
.terms-and-conditions-p {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.03em;
    text-align: left;
    color: #2747dd;
    text-decoration: underline;
}

.terms-and-conditions-notice-content{
    white-space: nowrap;
}

@media only screen and (max-width: 1400px) {
    .terms-and-conditions-notice-content {
        padding-right: 0px;
      }
}

@media only screen and (max-width: 1200px) {
    .terms-and-conditions-notice-content, .terms-and-conditions-notice-content a {
        font-size: 15px;
    }
}

@media only screen and (max-width: 900px) {
    .terms-and-conditions-notice-content, .terms-and-conditions-notice-content a {
        font-size: 12px;
    }
    .terms-and-conditions-notice {
        margin-top: 40px;
      }
      .terms-and-conditions-notice-content {
          margin-bottom: 40px;
        }
}

@media only screen and (max-width: 750px) {
    .terms-and-conditions-notice-content svg {
        width: 14px;
        height: 14px;
        margin-right: 5px;
    }
    .terms-and-conditions-notice-content, .terms-and-conditions-notice-content a {
        font-size: 10px;
    }
}

@media only screen and (max-width: 600px) {
    .terms-and-conditions-notice-content svg {
        width: 10px;
        height: 10px;
        margin-right: 2px;
    }
    .terms-and-conditions-notice-content, .terms-and-conditions-notice-content a {
        font-size: 8px;
    }
}

@media only screen and (max-width: 1000px) {
    .terms-and-conditions-content {
        padding-left: 70px;
        padding-right: 70px;
    }
}

@media only screen and (max-width: 500px) {
    .terms-and-conditions-content {
        padding-left: 16px;
        padding-right: 19px;
        margin-top: 42px;
    }
    .terms-and-conditions-notice {
        display: none;
    }
}

@media only screen and (max-width: 450px) {
    .terms-and-conditions-image-text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0.03em;
        color: #ffffff;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        height: 259px;
    }

    .terms-and-conditions-content-description {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 0.03em;
        color: rgba(0, 0, 0, 0.7);
    }
    .terms-and-conditions-content-container .terms-and-conditions-content:not(:first-child){
        margin-top: 82px;
    }
}
/* @media only screen and (max-width: 1550px) {
  .strategy-pnl-summary > div,
  .strategy-pnl-weekly > div,
  .strategy-pnl-monthly > div,
  .strategy-pnl-yearly > div,
  .strategy-pnl-relative > div,
  .strategy-pnl-max > div,
  .strategy-pnl-trade > div {
    transform: scale(0.95);
    transform-origin: left;
  }
}

@media only screen and (max-width: 1470px) {
  .strategy-pnl-summary > div,
  .strategy-pnl-weekly > div,
  .strategy-pnl-monthly > div,
  .strategy-pnl-yearly > div,
  .strategy-pnl-relative > div,
  .strategy-pnl-max > div,
  .strategy-pnl-trade > div {
    transform: scale(0.92);
    transform-origin: left;
  }
} */

.str-p-l-container {
  min-width: 0px;
}

.strategy-pnl-yearly .special-label {
  background-color: rgb(243, 246, 255) !important;
}

.strategy-pnl-relative .special-label {
  background-color: rgb(243, 246, 255) !important;
}

.strategy-pnl-max .special-label {
  background-color: rgb(243, 246, 255) !important;
}

@media only screen and (max-width: 1470px) {
  .str-p-l-flex-top {
    gap: 80px !important;
  }
}

@media only screen and (max-width: 1400px) {
  .str-p-l-flex-top {
    gap: 60px !important;
  }
}

@media only screen and (max-width: 1350px) {
  .str-p-l-flex-top {
    gap: 40px !important;
  }
}

@media only screen and (max-width: 1270px) {
  .str-p-l-flex-top {
    gap: 20px !important;
  }
}

.strategy-pnl>div>div {
  overflow-x: scroll;
}

@media only screen and (max-width: 1250px) {
  .StrategyPLPickerResponsive {
    flex-wrap: wrap;
    justify-content: flex-start !important;
    padding-left: 22px;
  }
}

@media only screen and (max-width: 500px) {
  .trade-log-custom {
    white-space: nowrap;
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: center;
    padding: 0px !important;
    gap: 9px !important;
  }

  .trade-log-custom>div:first-child {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .trade-log-custom * {
    font-size: 14px !important;
    line-height: 28px !important;
  }
}

@media only screen and (max-width: 380px) {
  .trade-log-custom * {
    font-size: 13px !important;
  }
}
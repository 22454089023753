.accordion-summary {
  cursor: pointer;
  border: 1px solid rgba(178, 178, 178, 0.7);
  padding: 20px;
  border-radius: 11px;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 14px;
}

.summaryContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.accordion {
  margin-top: 30px;
}

.accordion-content {
  border-top: 1px solid rgba(178, 178, 178, 0.7);
  padding: 20px 0;
  margin-top: 20px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 15px;
}

@media only screen and (max-width: 800px) {
  .accordion-item .question {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.03em;

    color: #000000;
  }

  .accordion-item .accordion-content {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.03em;

    color: rgba(0, 0, 0, 0.7);
  }
  .accordion-summary {
    padding: 15.3px 19px;
  }
}
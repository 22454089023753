.live-today-page {
  height: 100vh;
  width: 90%;
  font-family: 'Poppins';
  padding: 10px 0;
}

.live-today-heading {
  font-family: 'Poppins', sans-serif !important;
  padding: 10px 20px;
  border-bottom: solid #b2b2b2b2;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.66px;
  margin-bottom: 34px;

  span {
    border-bottom: solid 3px #2747dd;
    padding: 9px;
  }
}

.str-p-l-live-today-heading {
  margin-top: 4px;
  font-family: 'Poppins', sans-serif !important;
  padding: 10px 20px;
  border-bottom: solid #b2b2b2b2;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.66px;
  margin-bottom: 34px;

  span {
    border-bottom: solid 3px #2747dd;
    padding: 9px;
  }
}

.live-today-container {
  border-radius: 3px;
  background: #f3f6ff;
  padding: 35px;
  width: 100%;
}

.live-today-container-header {
  border-bottom: solid;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 17px;
}

.live-today-container-header-left-half {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.48px;

  span {
    color: #2747dd;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.6px;
  }
}

.live-today-container-header-right-half {
  align-items: center;

  span {
    margin-right: 6px;
  }

  .lots-container {
    justify-content: space-between;
    /* width: 64px; */
    border: 1px solid #000;
    border-radius: 20px;
    padding: 0px 1px 0px 6px;
  }

  .cancel {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 55px;
    height: 22px;
    border-radius: 20px;
    border: 1px solid rgba(0, 0, 0, 0.7);
    padding: 2.5px 3px;
  }
}

@media only screen and (max-width: 500px) {
  .live-today-container-header-right-half {
    span {
      margin-right: 0px;
      margin-left: 6px;
    }
  }

  .cancel {
    margin-left: 6px;
  }
}

.live-today-container-middle {
  padding: 20px 0;
  display: flex;
  border: none;
  border-bottom: solid;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.48px;

  .key-value-pair {
    margin-right: 10%;
  }

  .key {
    margin-right: 7px;
  }

  .value {
    font-weight: 500;
  }
}

.live-today-table {
  display: flex;
  flex-direction: column;

  table {
    width: 100%;
  }

  th,
  td {
    padding: 15px 0;
    border-bottom: 1px solid #dddddd;
  }

  th {
    text-align: left;
    font-weight: 600;
    max-width: 35px;
    color: #000;
    letter-spacing: 0.48px;
  }

  td {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    line-height: 29.5px;
    letter-spacing: 0.42px;
    color: #252525;
  }

  .live-today-table-total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    width: 100%;
  }
}

.kill-popup-card {
  position: fixed;
  top: 50%;
  left: 50%;
  background: #ffffff;
  border-radius: 8px;
  min-height: 200px;
  min-width: 400px;
  color: rgb(125, 122, 122);
  border: solid 0.5px;
  transform: translate(-50%, -50%);
  text-align: center;
}

.live-today-table-mobile-heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #000000;
  margin-top: 20.51px;
  padding-bottom: 17.16px;
  border-bottom: 1px solid rgba(178, 178, 178, 0.7);
}

.live-today-table-mobile-data {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.03em;
  color: #252525;
  margin-top: 12.9px;
  padding-bottom: 15.16px;
  border-bottom: 1px solid rgba(178, 178, 178, 0.7);
}

@media only screen and (max-width: 800px) {
  .live-today-page {
    width: 100%;
  }

  .live-today-page span:not(:nth-child(2)) .live-today-container {
    margin-top: 25px;
  }

  .live-today-container {
    padding: 10px 9px;
  }

  .live-today-heading {
    padding-left: 15px;
    position: relative;
    right: 20px;
    width: 100%;
    margin-bottom: 29px;
  }

  .live-today-heading span {
    margin-left: 14px;
    padding-left: 0px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.06em;

    color: #000000;
  }

  .live-today-container-header {
    margin-left: 11px;
  }

  .live-today-container-header-left-half {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.03em;

    color: #000000;

    span {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 0.03em;
      color: #2747dd;
    }
  }

  .live-today-container-header-right-half {
    margin-left: 2px;
  }

  .live-today-container-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(178, 178, 178, 0.7);
  }

  .live-today-container-middle {
    border-bottom: 1px solid rgba(178, 178, 178, 0.7);
    display: grid;
    grid-template-columns: auto auto;
  }

  .live-today-container-middle {
    .key {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.03em;
      color: #000000;
    }

    .value {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.03em;
      color: #000000;
    }
  }

  .key-value-pair {
    margin: 0px !important;
    padding: 0px !important;
    width: fit-content;
  }
}

@media only screen and (max-width: 420px) {
  .live-today-container-middle {
    border-bottom: 1px solid rgba(178, 178, 178, 0.7);
    display: grid;
    grid-template-columns: auto;
  }
}

.live-today-responsive-container * {
  box-sizing: border-box;
}

.subscribe-popup-card {
  z-index: 100;
}

.popup-card-header {
  z-index: 1000;
}

.table-wrapper {
  overflow-x: auto;
}

.table-wrapper table {
  min-width: 800px; /* Adjust this width based on your table's content */
}

@media only screen and (max-width: 800px) {
  .popup-card-header {
    width: 350px;

    article {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.03em;

      color: #000000;
    }

    p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 300 !important;
      font-size: 11px !important;
      line-height: 16px !important;
      letter-spacing: 0.03em !important;
      color: rgba(0, 0, 0, 0.7);
    }

    .popup-card-terms-conditions {
      span {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.03em;
        color: #000000;
        text-align: left;
      }
    }

    .popup-card-button-row {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 21px;

      button {
        margin: 0px;
      }
    }

    .popup-card-row {
      margin-top: 9.81px;
      width: 207.18px;
      height: 33.21px;

      article {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.03em;
        color: #000000;
      }

      input {
        width: 90px;
      }
    }
  }

  .kill-popup-card {
    width: 350px;
    span {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 0.03em;
      margin: 0px;
      color: #000000;
    }

    p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 0.03em;
      color: rgba(0, 0, 0, 0.7);
      margin: 0px;
      margin-top: 19px;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 21px;
      margin-top: 22px;
      button {
        margin: 0px;
      }
    }
  }
}

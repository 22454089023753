/* src/index.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
  --primary-backround-color: #2747dd;
  --primary-text-color: #ffffff;
  --button-text-color: #ffffff;
  --border-radius: 5px;
}

body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container-flex-column {
  display: flex;
  flex-direction: column;
}

input {
  border: 1pt solid var(--primary-backround-color);
  border-width: 2pt;
  border-radius: var(--border-radius);
  margin: 4pt;
  padding: 6pt 10pt;
}

.image-coverfit {
  object-fit: contain;
}

button {
  font-family: poppins;
  border: none;
  border-radius: var(--border-radius);
  background-color: var(--primary-backround-color);
  color: var(--button-text-color);
  padding: 6pt 10pt;
  font-size: 12pt;
  cursor: pointer;
  margin: 4pt;
}

button[disabled] {
  background-color: #e0e0e0;
  opacity: 0.7;
  color: #9e9e9e;
}

.input[disabled] {
  background-color: #e0e0e0;
  opacity: 0.2;
  color: #9e9e9e;
}

.button:hover {
  transform: scale(1.1);
}

.mini-icons {
  font-size: 20px !important;
}

.subscription-section-main {
    font-family: poppins;
    width: 100%;
    background-color: #F3F6FF;
    height: 1000px;
    padding-top: 10px;

}

.subscription-table-container {
    overflow: auto;
    max-height: 420px;
    /* Adjust the max-height as needed */
}


.subscription-heading {
    width: 220px;
    text-align: left;
    font-size: 30px;
    font-weight: 600;
    line-height: 45px;
    letter-spacing: 0.03em;
    /* border: solid; */
    margin-top: 20px;
    position: relative;
    left: 290px;


}

.subscription-section-parent {
    display: flex;
    justify-content: space-between;
    margin-top: -40px;
}

.subscription-section-child1 {
    margin-left: 50px;
    width: 20%;
    background: #fff;
    height: 400px;
    /* border: 1px solid; */
}

.subscription-section-child2 {
    width: 100%;
    background: #fff;
    height: 465px;
    /* border: 1px solid; */
    /* margin-left: 50px; */
}

.subscription-table-main {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.03em;
    text-align: left;
    box-shadow: 0px 1px 1px 0px #00000040;
    background: #FFFFFF;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #000000B2;
}

table {
    margin-top: 10px;
}

.subscription-table-heading {
    background: #FFFDF3;

}

.subscription-table-data {
    text-align: left;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #000000B2;

}

.subscription-header-row-data {
    text-align: left;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #000000;
    padding-top: 10px;
    padding-bottom: 10px;

}

.subscription-table-button {
    background: "#2747DD";
    color: "#FFFFFF";
    width: "118px";
    height: "34px";
    border-radius: '2.3px';
}

@media only screen and (max-width: 1100px) {
    .subscription-section-child2 {
        margin-right: 0px;
        width: 100%;
    }
}

@media only screen and (max-width: 1000px) {
    .subscription-table-container {
        overflow: scroll;
    }

    .subscription-table-container table {
        width: 900px;
    }

    .subscription-section-child2 {
        height: auto;
        width: calc(100vw - 420px);
    }
}

@media only screen and (max-width: 800px) {
    .subscription-section-child2 {
        height: auto;
        width: auto;
    }
}
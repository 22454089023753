.trading-calendar {
  font-family: Arial, sans-serif;
  padding: 20px;
  padding-top: 1rem;
}

.strategy-pnl-yearly .trading-calendar {
  background: rgb(243, 246, 255);
  border-radius: 0.6rem;
}

.yearly-calendar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.yearly-calendar {
  transform-origin: center;
}

@media only screen and (max-width: 1600px){
  .yearly-calendar {
    transform: scale(0.9);
  }
  .total-pnl {
    margin-left: 90px !important;
  }
}

@media only screen and (max-width: 1440px){
  .yearly-calendar {
    transform: scale(0.8);
  }
  .total-pnl {
    margin-left: 150px !important;
  }
}

@media only screen and (max-width: 1300px){
  .yearly-calendar {
    transform: scale(0.7);
  }
  .total-pnl {
    margin-left: 205px !important;
  }
}

@media only screen and (max-width: 500px){
  .total-pnl {
    margin-left: 0px !important;
    padding-top: 55px !important;
  }
  .total-pnl {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 12px !important;
  }
}

.yearly-calendar-week-indicator {
  padding-top: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 9.5px;
  margin-right: 15px;
}

.year-selector {
  margin-bottom: 10px;
}

.calendar-grid {
  display: flex;
  gap: 5px;
}

.month {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px; /* Adjust as needed for spacing between day blocks */
  padding: 10px; /* Add padding to create space between month cards */
}


.days {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-flow: column;
  width: 100%;
  grid-gap: 3.34px;
}

.day-block {
  width: 10.85px;
  height: 10.93px;  
  background-color: #ebedf0;
  cursor: pointer;
}

/* Color classes for different PnL values */
.positive-pnl {
  background-color: #2ACD1C;
}

.negative-pnl {
  background-color: #F82929;
}

/* .zero-pnl {
  background-color: grey;
} */


.day-block:hover {
  opacity: 0.8;
}

/* Tooltip styles */
.day-block::before {
  content: attr(title);
  position: absolute;
  display: none;
  padding: 5px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  font-size: 12px;
  white-space: pre-line;
}

.day-block:hover::before {
  display: block;
}

.day-block[data-color^="rgb(155"] {
  background: linear-gradient(to bottom, rgba(155, 255, 105, 0.8), rgba(155, 255, 105, 1));
}

.day-block[data-color="#ebedf0"] {
  background-color: #ebedf0;
}

.day-block[data-color="rgb(214, 102, 102)"] {
  background-color: rgb(214, 102, 102);
}

.container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  font-family: "Poppins", sans-serif;
  padding: 20px;
  letter-spacing: 0.42px;
  position: relative;
  background-color: rgb(243, 246, 255);
}

.container-responsive {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  font-family: "Poppins", sans-serif;
  padding: 0px;
  letter-spacing: 0.42px;
  position: relative;
  background-color: #FFF;
}

.filters {
  display: flex;
  margin-top: 5%;
  flex-wrap: wrap;
  border-radius: 3px;
  background-color: #fff;
  width: 256px;
  padding: 10px;
}

.filter-title-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: solid rgba(178, 178, 178, 0.7);
  color: rgba(0, 0, 0, 0.7);
  font-size: 14px;
  font-style: normal;
  font-weight: lighter;
  line-height: normal;
  letter-spacing: 0.42px;
  height: 50px;
  margin-bottom: 30px;
  padding: 0 10px;
}

.filter-title-bar :last-child {
  cursor: pointer;
}

.filter-count {
  color: #000000b2;
  padding: 5.5px 10px;
  border-radius: 50%;
  background-color: #b2b2b24d;
}

.filter-section {
  display: flex;
  flex-direction: column;
  color: #000000b2;
  font-weight: 500;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding: 0 8px;
  margin-top: -10px;
}

.filter-section h3 {
  margin-top: -10px;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.42px;
  color: rgba(0, 0, 0, 0.7);
}

.filter-section label {
  margin-bottom: 12px;
  width: 100%;
  cursor: pointer;
}

.filter-section input {
  margin-right: 7px;
}

.button-mobile {
  display: flex;
}

/*  */

.risk-options {
  display: flex;
  justify-content: space-evenly;
}

.risk-option {
  border: 1px solid #ccc;
  flex-direction: column;
  margin-right: 10px;
  cursor: pointer;
  width: 63px;
  height: 62px;
  flex-shrink: 0;
  border-radius: 5px;
  font-size: 15px;
}

.risk-option.selected {
  border: solid 1.5px;
}

.risk-options .low,
.risk-options .medium,
.risk-options .high {
  border: none;
  padding: 8px 10px;
  border-radius: 50%;
  background-color: rgba(178, 178, 178, 0.3);
}

.card {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
}

.filters {
  display: flex;
  flex-direction: row;
}

.strategy-landing-page {
  display: flex;
  width: 902px;
  flex-direction: column;
  position: relative;
  padding: 10px;
  transition: all 0.3s ease-in-out;
  z-index: 2;
}

.strategy-tabs {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  width: 90%;
  border-bottom: solid #b2b2b2b2;
}

.strategy-tabs .tab-option {
  padding: 5px 10px;
  padding-top: 10px;
  background-color: transparent;
  cursor: pointer;
  border-bottom: solid #2747dd 2px;
  font-size: 16px;
  letter-spacing: 0.48px;
  margin-bottom: -1px;
  height: 20px;
  min-width: 20%;
}

.strategy-tabs-responsive {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.strategy-tabs-responsive .tab-option-responsive {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px 0px;
  margin: 0px 10px;
  padding-top: 10px;
  background-color: transparent;
  cursor: pointer;
  gap: 13.9px;
  padding-bottom: 13px;
  border-bottom: 1.74359px solid #2747dd;
  width: fit-content;
}

.strategy-tabs-responsive .tab-option-responsive span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.03em;
  color: #000000;
}

.strategy-tabs-responsive .tab-option-responsive select {
  padding: 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.03em;
  color: #000000;
}

.strategy-tabs-responsive .tab-option-responsive-border {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px;
  padding-top: 10px;
  margin-top: 16px;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  gap: 13px;
  border-bottom: 1px solid #b2b2b2b2;
  padding-left: 9px;
}

.strategy-title-style-responsive {
  gap: 13px;
  font-family: "poppins";
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.06em;
  text-align: left;
  padding-bottom: 20px;
  border-bottom: 3px solid #2747dd;
  padding-left: 29px;
}

.tab-content {
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 100%;
  margin-left: 30px;
}

.tab-content-responsive {
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 100%;
}

.tab-content-responsive > div {
  width: 100%;
}

.sortby-options {
  /* flex-direction: column; */
  position: absolute;
  z-index: 999;
  top: 85%;
  background-color: #ffffff;
  margin-left: 80px;
  width: 80%;
  /* border: solid; */
  border-radius: 5px;
  overflow: hidden;
  padding: 10px 12px;
  padding-right: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 13px;
  /* border: 1px solid #000000b2; */
  box-shadow: 1px 1px 10px 0px #00000040;
}

.sort-option {
  background: #ffffff;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0.03em;
  text-align: left;
  margin: 0;
  border-radius: 0;
  padding: 0px;
}

.popu {
  gap: 13px;
  padding-bottom: 13px;
  border-bottom: 2px solid #2747dd;
}

.dropdown-icon {
  font-size: 8px !important;
  width: 8px;
}

.search-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  border-bottom: solid #2747dd 2px;
  width: 200px;
  gap: 0;
}

.strategy-tabs-responsive .tab-search-option-responsive {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 14px 0px;
  margin: 0px 14px;
  background-color: transparent;
  cursor: pointer;
  width: 67%;
  gap: 16px;
  border-bottom: solid #b2b2b2b2 1px;
  margin-top: 8px;
  min-width: 257px;
}

.popu-fil-responsive {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin-top: 19px;
  margin-bottom: 0px;
}

.search-input {
  flex-grow: 1;
  background-color: transparent;
  border: none;
  outline: none;
  margin: 0;
  padding: 0px 0px 0px 6px;
}

.filter-span {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding-left: 16px;
  padding-right: 6px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 6.97px;
}

.filter-span-single-strategy-page {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding-left: 18.5px;
  padding-right: 16px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 6.97px;
  position: relative;
  right: 20px;
  bottom: 10px;
  cursor: pointer;
}

.strategy-tabs-responsive input {
  padding: 0;
  margin: 0;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.03em;
  text-align: left;
}

input::placeholder {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;
}

/* StrategyCard CSS */

.horizontal-card-responsive {
  display: flex;
  /* border: 1px solid #ccc; */
  border: none;
  padding: 18px 24px;
  margin-top: 20px;
  border-radius: 3.31px;
  background: #f3f6ff;
  font-family: "Poppins-Regular", Helvetica;
  width: auto;
  flex-direction: column;
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
}

.horizontal-card-responsive-special {
  display: flex;
  /* border: 1px solid #ccc; */
  border: none;
  padding: 18px 24px;
  border-radius: 3.31px;
  background: #f3f6ff;
  font-family: "Poppins-Regular", Helvetica;
  width: auto;
  flex-direction: column;
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
}

/* .horizontal-card-responsive:hover {
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
} */

.horizontal-card {
  height: 170px;
  display: flex;
  border: 1px solid #ccc;
  padding: 15px;
  margin-top: 20px;
  border-radius: 3.31px;
  background: #fff;
  font-family: "Poppins-Regular", Helvetica;
  width: 810px;

  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;
}

.horizontal-card:hover {
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
}

.left-half {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 63%;
  margin-right: 24px;
  padding: 18px;
  padding-right: 0;
  box-sizing: border-box;
}

.left-half-resposive {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-right: 0px;
  padding: 0px;
  padding-right: 0;
  box-sizing: border-box;
  gap: 13px;
}

.left-half-resposive-special {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-right: 0px;
  padding: 0px;
  padding-right: 0;
  box-sizing: border-box;
  gap: 13px;
  margin-left: 12.425px;
}

.card-image {
  width: 75px;
  height: 75px;
  object-fit: cover;
  border-radius: 8px;
}

.card-image-responsive {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 2.1px;
}

.card-info {
  height: 75px;
  margin-left: 20px;
  min-width: 80%;
}

.card-info-resposive {
  width: 100%;
  margin-left: 0px;
  min-width: 80%;
}

.card-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 20px;
  margin-bottom: -5px;
}

.card-title-responsive {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: -5px;
}

.card-heading {
  font-family: "Poppins";
  min-width: 100px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #000000;
  margin: 0px;
}

.card-heading-responsive {
  padding: 0;
  color: #000;
  min-width: 100px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.48px;
  margin: 0px;
}

.badge-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.badge {
  margin-right: 5px;
  padding: 0 5px;
  color: #fff;
  border-radius: 3px;
  font-size: 11px;
  height: 21px;
}

.new-badge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3.546px;
  background: #2747dd;
  min-width: 45px;
  max-width: 500px;
  flex-wrap: wrap;
  font-size: 11px;
  margin-right: 10px;
  border-radius: 4px;
  color: white;
  margin: 5px;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.risk-badge {
  font-size: 11px;
  color: red;
  max-width: 90px;
  height: 20.089px;
  border: 1px solid rgba(178, 178, 178, 0.7);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin: 5px;
  max-width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;

  .icon {
    width: 16.991px;
    height: 9.329px;
    margin-bottom: 1px;
  }
}

.new-badge-responsive {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 3.546px;
  background: #2747dd;
  min-width: 37px;
  max-width: 500px;
  flex-wrap: wrap;
  font-size: 8px;
  margin-right: 10px;
  border-radius: 4px;
  color: white;
  margin: 3px;
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
}

.risk-badge-responsive {
  font-size: 8px;
  color: red;
  max-width: 90px;
  height: 20.089px;
  border: 1px solid rgba(178, 178, 178, 0.7);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin: 3px;
  max-width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;

  .icon {
    width: 16.991px;
    height: 9.329px;
    margin-bottom: 1px;
  }
}

.card-desc {
  font-size: 14px;
  color: #000000b2;
  letter-spacing: 0.42px;
  width: 300px;
}

.card-desc-responsive {
  width: auto;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 17px;
  letter-spacing: 0.03em;
  color: rgba(0, 0, 0, 0.7);
  padding: 0px;
  margin: 16px 0px;
}

.card-desc-responsive-special {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.03em;
  color: rgba(0, 0, 0, 0.7);
  width: auto;
  padding: 0px;
  margin: 21px 0px;
  margin-bottom: 24px;
}

.card-author {
  margin-top: -10px;
  padding-top: 2px;
  color: #00000080;
  font-size: 14px;
}

.card-author-responsive {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.03em;
  color: rgba(0, 0, 0, 0.7);
}

.card-title-inner-responsive {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  margin-top: 6px;
}

.card-title-inner-responsive .badge-container {
  gap: 7.44px;
}

.card-title-inner-responsive .badge-container .badge:first-child {
  padding: 0.91px 9.2px 2.14px 9.98px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 12px;
  letter-spacing: 0.03em;
  color: #f3f6ff;
  min-width: auto;
  max-width: max-content;
  height: auto;
}

.card-title-inner-responsive .badge-container .badge:nth-child(2) {
  padding: 2.3px 8.42px 2.74px 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 9px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #f82929;
  min-width: auto;
  max-width: max-content;
  height: auto;
  border: none;
  outline: 1px solid rgba(178, 178, 178, 0.7);
}

.card-title-inner-responsive .badge-container .badge {
  margin: 0px;
}

.right-half {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  box-sizing: border-box;
  padding-top: 30px;
  width: 43%;
}

.right-half-responsive {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
}

.card-metrics {
  display: flex;
}

.card-metrics>div {
  display: flex;
  flex-direction: column;
}

.card-metrics p {
  margin: 5px 5px 0 0;
  padding: 3px;
  font-size: 14px;
  align-items: center;
  display: flex;
  /* width: 90px; */
  white-space: nowrap;
  max-width: 200px;
}

.card-metrics-responsive {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.card-metrics-responsive-span-cut>div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-metrics-responsive .span-cut {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
}

.card-metrics-responsive>div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.card-metrics-responsive p,
.card-metrics-responsive-span-cut p {
  margin: 0px;
  padding: 0px;
}

.min-cap,
.cap-deployed,
.cagr {
  color: #000000b2;
  padding-top: 5px;
}

.value {
  color: #000000b2;
}

.cagr-value {
  color: #2acd1c;
}

.min-cap-responsive,
.cap-deployed-responsive,
.cagr-responsive {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.03em;
  color: rgba(0, 0, 0, 0.7);
}

.value-responsive {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: rgba(0, 0, 0, 0.7);
}

.cagr-value-responsive {
  color: #2acd1c;
}

.card-metrics-responsive-span-cut p {
  width: max-content;
}

.check-circle-icon {
  color: #2747dd;
  padding: 10px;
  border-radius: 50%;
  margin-top: -50px;
  border-top: solid black;
  background-color: #fff;
}

.dropdown {
  border: none;
  width: 160px;
  height: 25px;
  background-color: aliceblue;
  text-align: center;
}

/* Single Strategy Page Card Start*/

.single-ai-card-container {
  margin: 0px 20px;
  margin-top: 15px;
  margin-bottom: 8px;
  width: 90%;
}

.single-ai-strategy {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: row;
  background: #ffffff;
  border-radius: 4.61px;
  padding: 34px 31px;
  box-sizing: border-box;
}

.single-ai-strategy-1 {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  gap: 32px;
}

.single-ai-strategy-1-1 {
  width: 112px;
  height: 112px;
  border-radius: 4.61px;
}

.single-ai-strategy-1-2 {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: flex-start;
}

.single-ai-strategy-1-2-strategy {
  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #000000;
  display: flex;
  flex-direction: row;
  gap: 8.6px;
}

.single-ai-strategy-1-2-author {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24.58px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #000000b2;
}

.single-ai-strategy-1-2-desc {
  width: 60%;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24.58px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #000000b2;
}

.single-ai-strategy-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-right: 6px;
  gap: 30px;
  position: relative;
  top: 3.8px;
}

.single-ai-strategy-2-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3.51px;
  white-space: nowrap;
}

.single-ai-strategy-2-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3.51px;
  white-space: nowrap;
}

.single-ai-strategy-2-title {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.22px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #000000b2;
}

.single-ai-strategy-2-sub {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #000000b2;
}

.single-ai-strategy-2-sub-special {
  color: #2acd1c;
  font-weight: 500;
}

.single-ai-strategy-1-2-strategy-1 {
  background: #2747dd;
  font-family: Poppins;
  font-size: 12.1px;
  font-weight: 500;
  line-height: 18.15px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #ffffff;
  padding-top: 2.36px;
  padding-bottom: 1.93px;
  padding-left: 7.7px;
  padding-right: 8.7px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3.9px;
  margin-left: 5.4px;
}

.single-ai-strategy-1-2-strategy-2 {
  border: 1.21px solid #b2b2b2b2;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 14.75px;
  letter-spacing: 0.03em;
  text-align: center;
  color: #f82929;
  padding-top: 4.51px;
  padding-bottom: 2.04px;
  padding-left: 14.53px;
  padding-right: 11.72px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2.77px;
  gap: 5.92px;
}

.strategy-pnl-relative .pl-line-container {
  background: rgb(243, 246, 255);
  border-radius: 0.6rem;
}

/* Single Strategy Page Card End*/

@media only screen and (max-width: 700px) {}
.filter-screen {
    position: fixed;
    top: 66px;
    left: 0px;
    width: 100%;
    height: calc(100vh - 66px);
    z-index: 10;
    background-color: #ffffff;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    overflow: scroll;
}

.filter-screen * {
    box-sizing: border-box;
}

.filter-screen-part-1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 13px;
    width: 70%;
    padding-top: 10px;
    margin-bottom: 29px;
}

.filter-screen-heading {
    font-family: "Poppins", sans-serif !important;
    padding: 10px 20px;
    border-bottom: solid #b2b2b2b2;
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0.66px;
    margin-bottom: 34px;
    width: 100%;
    padding-left: 8px;
    position: relative;
    padding-bottom: 0px;
    margin: 0px;

    span {
        border-bottom: solid 3px #2747dd;
        padding: 9px;
        padding-left: 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin: 0px;

        div {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.06em;
            color: #000000;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 14px;
            flex-direction: row;
            margin: 0px;
            white-space: nowrap;

            p {
                padding: 2px 8px;
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.03em;
                color: #000000;
                border-radius: 50%;
                background: #ffe484;
                margin: 0px;
            }
        }
    }
}

.filter-screen-part-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-left: 22px;
    padding-right: 17px;
    width: 100%;
}

.filter-screen-part-2-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0px 0px 18.36px 0px;
    border-bottom: 0.5px solid rgba(178, 178, 178, 0.7);
}

.filter-screen-part-2 div {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #000000;
}

.filter-screen-part-2 button {
    padding: 7px 23px;
    background: #2747dd;
    border-radius: 2.47747px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #ffffff;
    margin: 0px;
}

.filter-screen-part-3 {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 22px;
    margin-top: 17px;
}

.filter-screen-part-3-heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #000000;
}

.filter-screen-part-3-options {
    margin-top: 19px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 22px;

    div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 13px;

        input {
            display: none;
        }

        label {
            cursor: pointer;
        }

        input[type="radio"]:not(:checked)+label {
            width: 20px;
            height: 20px;
        }

        input[type="radio"]:not(:checked)+label>.checked {
            display: none;
        }

        input[type="radio"]:checked+label {
            width: 20px;
            height: 20px;
        }

        input[type="radio"]:checked+label>.notchecked {
            display: none;
        }

        span {
            width: 100%;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.03em;
            color: #000000;
        }
    }
}

.filter-screen-part-4 {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 22px;
    margin-top: 35px;
}

.filter-screen-part-4-heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #000000;
}

.filter-screen-part-4-options {
    margin-top: 19px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 22px;

    div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 13px;

        input {
            display: none;
        }

        label {
            cursor: pointer;
        }

        input[type="radio"]:not(:checked)+label {
            width: 20px;
            height: 20px;
        }

        input[type="radio"]:not(:checked)+label>.checked {
            display: none;
        }

        input[type="radio"]:checked+label {
            width: 20px;
            height: 20px;
        }

        input[type="radio"]:checked+label>.notchecked {
            display: none;
        }

        span {
            width: 100%;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.03em;
            color: #000000;
        }
    }
}

.filter-screen-part-5 {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 35px;
}

.filter-screen-part-5-heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #000000;
    padding-left: 22px;
}

.filter-screen-part-5-flex {
    margin-top: 19px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 55px;
    padding-bottom: 45px;
    margin-bottom: 20px;
    padding-left: 23px;

    border-bottom: 0.5px solid rgba(178, 178, 178, 0.7);

    div {
        cursor: pointer;
        outline: 1.20967px solid rgba(178, 178, 178, 0.7);
        border-radius: 3.62901px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 6.6px 9.68px;
        gap: 13px;
        min-width: 76.21px;

        input {
            display: none;
        }

        label {
            cursor: pointer;
        }

        input[type="radio"]:not(:checked)+label {
            width: 20px;
            height: 20px;
        }

        input[type="radio"]:not(:checked)+label>.checked {
            display: none;
        }

        input[type="radio"]:checked+label {
            width: 20px;
            height: 20px;
        }

        input[type="radio"]:checked+label>.notchecked {
            display: none;
        }

        span {
            width: 100%;
            font-family: "Poppins";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.03em;
            color: #000000;
            text-align: center;
        }
    }
}

.filter-screen-part-5-flex-special-div {
    outline: 2.41934px solid #000000 !important;
}
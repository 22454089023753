.priceCardplan {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0px;
    background-color: white;
}

.pricePL {
    color: green;
}

.pricebacktest {
    color: rgba(39, 71, 221, 1);
}

.priceCol1 {
    border-right: 1px solid rgba(178, 178, 178, 0.7);
    margin-right: 20px;
    padding-left: 20px;
}

.priceCol2 {
    border-right: 1px solid rgba(178, 178, 178, 0.7);
    margin-right: 20px;
}

.textSecondary {
    color: rgba(0, 0, 0, 0.7);
}

@media only screen and (max-width: 800px) {
    .priceCardplan {
        grid-template-columns: 1fr;
    }

    .plan {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.03em;
        color: #000000;
        margin-left: 9px;
    }

    .textSecondary {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.03em;
        color: rgba(0, 0, 0, 0.7);
        margin-left: 9px;
        margin: 15px 9px 15px 9px;
    }

    .priceCol1,
    .priceCol2,
    .priceCol3 {
        border-right: none;
        margin-top: 11px;
        margin-left: 25px;
        padding: 0px;
        border-bottom: 1px solid rgba(178, 178, 178, 0.7);
        padding-bottom: 17px;
        width: fit-content;
    }

    .priceCol3 {
        border: 0px;
    }

    .priceCol1 h3,
    .priceCol2 h3,
    .priceCol3 h3 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.03em;
        color: #000000;
        margin: 0px;
    }

    .priceCol1 h5,
    .priceCol2 h5,
    .priceCol3 h5 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.03em;
        margin: 0px;
        padding-top: 9px;
    }

    .get-responsive-plans {
        margin-top: 73px;
        width: 100%;
    }

    .get-responsive-plan {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .get-responsive-plan-space-between {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.03em;
        color: #2747dd;
    }

    .get-responsive-plan-sub {
        margin-top: 5px;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: rgba(0, 0, 0, 0.7);
    }

    .get-responsive-plans .get-responsive-plan:not(:first-child) {
        margin-top: 26px;
    }

    .get-responsive-plan-backtest {
        justify-content: center;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .get-responsive-plan-backtest-switcher {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 10px;
            margin-top: 44px;
            margin-bottom: 50px;

            p {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.03em;
                color: #000000;
            }

            p.p-text-responsive {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.03em;

                color: #000000;
            }

            p.p-bold-text-responsive {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.03em;
            }
        }
    }
}

@media only screen and (max-width: 420px) {
    .get-responsive-plan-backtest {
        .get-responsive-plan-backtest-switcher {
            p.p-text-responsive {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 24px;
                letter-spacing: 0.03em;
                color: #000000;
            }

            p.p-bold-text-responsive {
                font-family: "Poppins";
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 24px;
                letter-spacing: 0.03em;
            }
        }
    }
}
.ml-5 {
  margin-left: 5px;
}

.marketnews-container {
  padding-left: 125px;
  padding-right: 200px;
  background: #f3f6ff;
  flex: 1;
}

.marketnewsrankings {
  color: #000;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.32px;
  margin-top: 0;
  padding-top: 30px;
  margin-left: 28px;
  margin-bottom: 0;
  line-height: 33px;
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@media only screen and (max-width: 380px) {
  .marketnewsrankings {
    font-size: 20px;
  }
}

@media only screen and (max-width: 350px) {
  .marketnewsrankings {
    font-size: 18px;
  }
}

.marketnewsrankings-btm-line {
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -17px;
  background: #2747dd;
  height: 3px;
}

.marketnews-horizontal-line {
  width: auto;
  height: 1px;
  background: rgba(178, 178, 178, 0.7);
  border: none;
  margin-left: 9px;
  margin-top: 17px;
  margin-bottom: 21px;
}

.marketnews-parent-pages {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 22px;
  padding-left: 27px;
  padding-right: 6%;
}

.marketnews-part1 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
}

.marketnews-part1-btn-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.marketnews-part1-btn,
.marketnews-part1-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #b2b2b2;
  background: transparent;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.03em;
  padding: 5px 10px;
  margin: 0;
  width: 9rem;
}

.marketnews-part1-btn-option {
  background: #f3f6ff;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.03em;
  padding: 5px 10px;
  margin: 0;
  width: 9rem;
  border-bottom: solid 0.5px;
  border-radius: 0;
}

.dropdown-options-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 45px;
  left: 10px;
  z-index: 999;
}

.marketnews-part1-btn svg path,
.marketnews-part1-btn svg path {
  width: 9px;
  height: 4.5px;
}

.marketnews-nav {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.06em;
  text-align: left;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.marketnews-part2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 317px;
  height: 29px;
  top: 193px;
  left: 807px;
  border-radius: 3px;
  border: 1px solid #b2b2b2;
  margin: 0;
  padding: 0;
  padding: 0px 11px;
}

.marketnews-part2-icon-cont {
  display: flex;
  align-items: center;
  justify-content: center;
}

.marketnews-part2 svg {
  width: 17px;
  height: 15px;
}

.marketnews-part2-search-input {
  border: none;
  outline: none;
  background: transparent;
  flex: 1;
  margin: 0;
  padding: 0;
  height: 100%;
  padding-left: 10px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.03em;
}

.marketnews-body {
  display: block;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 25px;
  width: auto;
  padding-right: 6%;
}

.marketnews-body-title {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.03em;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 9px;
  padding: 4px 0px;
  border-bottom: 1px solid #b2b2b2;
  position: relative;
  margin-top: 22px;
}

.marketnews-body-title-dot {
  width: 5px;
  height: 5px;
  background-color: #b2b2b2;
  border-radius: 50%;
  position: absolute;
  bottom: -3px;
  right: -3px;
}

.marketnews-body-title svg {
  width: 20px;
  height: 20px;
}

.marketnews-body-content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid #b2b2b2b2;
  margin-top: 15px;
}

.marketnews-body-content-title {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.03em;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.marketnews-body-content-title-text {
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Add ellipsis for overflowing text */
  white-space: nowrap; /* Prevent wrapping */
  margin-right: 8px; /* Add space between title and time */
}

.marketnews-body-content-title-time {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.03em;
  text-align: left;
  margin-left: 8px;
  flex-shrink: 0;
}

.marketnews-body-content-description {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.03em;
  text-align: left;
}

.marketnews-body-content-bottom {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 13px 0px;
}

.marketnews-body-content-bottom-parts {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 6px;
}

.marketnews-body-content-bottom-part-1 img {
  width: 19px;
  height: 19px;
  border-radius: 50%;
}

.marketnews-body-content-bottom-part-1 button {
  border-radius: 3.55px;
  font-family: Poppins;
  font-size: 11px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.03em;
  text-align: left;
  padding-top: 1px;
  padding-bottom: 2px;
  padding-left: 11px;
  padding-right: 12px;
  background: #5468c4;
  border: none;
  outline: none;
  color: #ffffff;
  margin-left: 1px;
}

.marketnews-body-content-bottom-part-1 a {
  font-family: Poppins;
  font-size: 11px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #000000b2;
  text-decoration: underline;
  margin-right: 20px;
  position: relative;
}

.marketnews-body-content-bottom-part-1 a svg {
  margin-left: 3px;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
}

.marketnews-body-content-bottom-part-2 {
  position: relative;
}

.marketnews-body-content-bottom-part-2 button {
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  font-family: Poppins;
  font-size: 11px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.06em;
  text-align: left;
  color: #000000b2;
  padding: 0;
  margin: 0;
  margin-left: 6px;
}

.marketnews-body-content-bottom-part-2 button:nth-last-child(2) {
  margin-right: 12px;
}

.marketnews-body-content-bottom-part-2 button:last-child {
  position: absolute;
  right: -20px;
}

.marketnews-body-content-bottom-part-2 button svg {
  margin-right: 6px;
}

.promo-box-container {
  width: 100%;
  box-sizing: border-box;
}

.promo-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  background: linear-gradient(90.83deg, #5468c4 25.71%, #7a86be 83.02%);
  width: auto;
  border-radius: 3px;
  padding-top: 16px;
  padding-left: 20px;
  padding-right: 29px;
  padding-bottom: 19px;
  margin-top: 21px;
}

.promo-box-parts {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.promo-box-parts-text1 {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.06em;
  text-align: center;
  color: #ffffff;
}

.promo-box-parts-text2 {
  font-family: Poppins;
  font-size: 11px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #ffffff8f;
}

.promo-box-parts button {
  background: #ffe484;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.03em;
  color: #252525;
  border-radius: 2.7px;
  filter: drop-shadow(2px 4px 3px rgb(92, 92, 92));
}

.marketnews-pages {
  width: 100%;
  margin-top: 14px;
  margin-left: 27px;
}

.marketnews-pages > div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 34px;
  width: fit-content;
}

.marketnews-btn1 {
  border: none;
  background: transparent;
  padding: 0px;
  margin: 0px;
}

.marketnews-btn2 {
  border: none;
  background: transparent;
  padding: 0px;
  margin: 0px;
}

@media only screen and (max-width: 1000px) {
  .marketnews-container {
    padding-left: 75px;
    padding-right: 100px;
  }
}

@media only screen and (max-width: 800px) {
  .marketnews-container {
    padding-left: 25px;
    padding-right: 50px;
  }
}

.marketnews-part1-responsive {
  margin-top: 15px;
  margin-left: 29px;
  justify-content: flex-start;
}

@media only screen and (max-width: 480px) {
  .marketnews-container {
    padding-left: 6px;
    padding-right: 0px;
  }

  .marketnews-body {
    padding-right: 20px;
  }

  .marketnewsrankings {
    margin-left: 41px;
  }

  .marketnews-horizontal-line {
    margin-right: 39px;
  }

  .marketnews-body-content-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    letter-spacing: 0.03em;
    color: #000000;
    align-items: flex-start;
  }

  .marketnews-body-content-description {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 22px;
    letter-spacing: 0.03em;
    color: rgba(0, 0, 0, 0.7);
  }

  .marketnews-body-content-title-time {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.03em;
    color: rgba(0, 0, 0, 0.7);
    margin-top: 6px;
  }

  .marketnews-body-content-bottom {
    flex-direction: column;
    align-items: flex-start;
    gap: 14px;
    justify-content: center;
  }

  .marketnews-part2 {
    border: 0px;
    border-bottom: 1px solid rgba(178, 178, 178, 0.7);
    align-items: flex-start;
    padding-left: 1px;
  }

  .marketnews-parent-pages {
    display: flex;
    align-items: flex-start;
    margin-top: 22px;
    padding-left: 28px;
    justify-content: flex-start;
  }

  .marketnews-part2-search-input {
    padding-bottom: 10px;
  }

  .marketnews-container {
    overflow: hidden;
  }
}

.App {}

.border-box {
  box-sizing: border-box;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.fit {
  width: fit-content;
}

.pointer {
  cursor: pointer;
}

.over-scroll {
  overflow: scroll;
}

.w-full {
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html {
  height: 100vh;
}

body {
  margin: 0;
  padding: 0;
}

table {
  border-collapse: collapse !important;
  border-spacing: 0 !important;
  width: 100%;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-space-btw {
  justify-content: space-between;
}

.w-100 {
  width: 100%;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.arrow-icon {
  width: 20.19px !important;
  height: 19.68px !important;
  top: 151.31px;
  left: 991.31px;
  color: white;
}

.strategy-pnl .footer-special {
  position: relative;
  right: 28px;
  width: 100%;
  padding: 40px 0px;
  padding-left: 83px !important;
  background: #f3f6ff;
}

.date-range-picker-strategy-pnl .DateRangePickerInput,
.date-range-picker-strategy-pnl #startDateId,
.date-range-picker-strategy-pnl #endDateId,
.date-range-picker-strategy-pnl .DateInput {
  background: transparent !important;
  font-family: Poppins;
  font-size: 15.4px;
  font-weight: 500;
  line-height: 23.1px;
  text-align: left;
  color: #000000b2;
  box-sizing: border-box;
}

.date-range-picker-strategy-pnl .DateRangePickerInput_arrow svg {
  display: none;
}

.date-range-picker-strategy-pnl .DateRangePickerInput {
  padding: 0px 10px;
}

.date-range-picker-strategy-pnl .DateRangePickerInput_arrow:before {
  content: "~";
}

.image-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #000000;
  border-radius: 3px;
  width: 271px;
  margin: 0px;
  padding-top: 11px;
  padding-bottom: 17px;
}

.image-picker div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image-picker button {
  margin: 0px;
  padding: 0px;
  background: #5468c4;
  border: 1px solid #00000026;
  box-shadow: 1px 1px 10px 0px #7a7a7a66;
  padding: 7px 30px;
  padding-top: 6px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #ffffff;
}

.image-dialog .MuiPaper-root {
  width: 100%;
}

.image-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0px;
  border-radius: 3px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  /* opacity: 0px; */
  border: 1px dashed #000000;
  padding: 18px 23px;
  padding-bottom: 26px;
}

.image-uploader button {
  margin: 0px;
  padding: 0px;
  background: #5468c4;
  border: 1px solid #00000026;
  box-shadow: 1px 1px 10px 0px #7a7a7a66;
  padding: 7px 30px;
  padding-top: 6px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #ffffff;
}

.image-uploader>div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image-uploader-inner {
  border: 1px solid #b2b2b2;
  padding-top: 14px;
  padding-left: 22px;
  padding-right: 17px;
  padding-bottom: 14px;
  /* 32px if the progress bar is there */
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 499px;
  margin-bottom: 22px;
}

.image-uploader-part-1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #000000;
  margin: 0px;
  padding: 0px;
  /* margin-bottom: 9px; if progress bar is there */
}

.image-uploader-part-2 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.03em;
  text-align: left;
  color: #b2b2b2;
  margin: 0px;
  padding: 0px;
}

.image-dialog-upload .MuiDialog-container .MuiPaper-root {
  max-width: 700px !important;
}

@media only screen and (max-width: 700px) {
  .image-uploader-inner {
    width: auto;
  }
  .image-uploader-part-1 span{
    max-width: calc(100% - 30px);
  }
}

.date-picker-responsive input {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #000000b2;
  padding-top: 11px;
  padding-bottom: 9px;
  padding-left: 26px;
}

.date-picker-responsive .MuiSelect-select {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #000000b2;
  padding-top: 11px;
  padding-bottom: 9px;
  padding-left: 26px;
}

.date-picker-responsive svg {
  width: 20px;
  height: 18px;
}

.table-scroll-x {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.calc-width-plus-1-rem {
  width: calc(100% + 1rem) !important;
}

@media only screen and (max-width: 380px) {
  div:has(> div.date-picker-responsive) {
    width: 130px !important;
  }

  .date-picker-responsive {
    width: 130px !important;
  }

  .date-picker-responsive input {
    padding-top: 11px;
    padding-bottom: 9px;
    padding-left: 10px;
  }

  .date-picker-responsive .MuiSelect-select {
    padding-top: 11px;
    padding-bottom: 9px;
    padding-left: 10px;
  }
}

.backtesting-dialogcontent h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.06em;
  color: #000000;
  white-space: nowrap;
}

.responsive-pricing-strategy {
  width: 100%;

  div {
    margin: 0px !important;
    margin-top: 20px !important;
    padding: 10px !important;
    box-sizing: border-box;
  }

  div.margin-0 {
    margin-top: 0px !important;
  }

  h2.strategyTextResponsive1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500 !important;
    font-size: 17.0327px !important;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.03em;
    color: rgba(0, 0, 0, 0.7);
  }

  h2.strategyTextResponsive2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 17.0327px !important;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.03em;
    
    color: rgba(0, 0, 0, 0.7);
  }
}

.responsive-pricing-strategy .button-per-month {
  margin-top: 0px !important;
}

.responsive-pricing-strategy>div {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.backtesting-dialogcontent img {
  width: "71.49pxpx";
  height: "71.22pxpx";
}

.hover-explore-strategies {
  transition: 0.2s all linear;
}

.hover-explore-strategies:hover {
  background-color: #ffffff !important;
  color: #2747dd !important;
  /* border: 1px solid #2747DD !important;  */
  box-shadow: 0px 0px 0px 1px #2747dd inset;
}

.footer-bg-special {
  background-color: #f3f6ff !important;
}

.no-wrap {
  white-space: nowrap;
}

.curved-line {
  background-image: url("data:image/svg+xml,%3Csvg width='1442' height='123' viewBox='0 0 1442 123' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 121.972C151.944 67.1508 570.437 19.5142 772.5 80C972.51 139.871 1281.38 86.4806 1441 1' stroke='%232747DD' stroke-opacity='0.22' stroke-width='2'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 2;
}

.rotated {
  transform: rotate(180deg);
}

@media only screen and (min-width: 1000px) {
  .special-width-background {
    max-width: calc(100% - 200px);
  }
}

.graph-animation-container {
  width: 100%;
  max-width: 1030px;
  height: 100%;
  max-height: 295px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 500px) {
  .graph-animation-container {
    height: 130px;

    .graph-text-animation {
      background-position: top;
    }
  }
}

.graph-line-animation {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  animation: revealGraph2 3s linear forwards;
}

.graph-line-animation::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(243, 246, 255);
  animation: revealGraph 3s linear forwards;
  box-shadow: -10px 0 30px rgb(243, 246, 255);
}

@keyframes revealGraph {
  0% {
    width: 100%;
  }

  100% {
    width: 0;
  }
}

@keyframes revealGraph2 {
  0% {
    background: url("assets/homepageimages/graph-line.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  99% {
    background: url("assets/homepageimages/graph-line.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  100% {
    background: url("assets/homepageimages/graph-area.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.graph-text-animation {
  width: 100%;
  height: 100%;
  background: url("assets/homepageimages/graph-texts.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 2;
  animation: revealText 3s linear forwards;
}

@keyframes revealText {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.support-container {
    background: #F3F6FF;
    width: 100%;
    height: 100vh;
    margin-top: -70px;


}

.support-heading {

    width: 250px;
    height: 45px;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 45px;
    letter-spacing: 0.03em;
    text-align: center;
    color: #000000;
    position: relative;
    left: 320px;
    /* border: 1px solid; */
    top: 40px;

}

.support-parent {
    display: flex;
    justify-content: space-between;
}

.support-child1 {
    margin-left: 50px;
    width: 20%;
    height: 400px;
    /* border: 1px solid; */
    background: #FFFFFF;
}

.support-child2 {
    margin-top: 60px;
    width: 100%;
    height: 400px;
    /* border: 1px solid; */
    /* background: #FFFFFF; */
    display: flex;
    justify-content: space-between;
}

.support-child2-section1 {
    width: 258px;
    height: 266px;
    border-radius: 3px;
    background: #FFFFFF;
    /* border: 1px solid; */
    text-align: center;

}

.support-child2-section2 {
    width: 258px;
    height: 266px;
    border-radius: 3px;
    background: #FFFFFF;
    /* border: 1px solid; */
    text-align: center;

}

.support-child2-section3 {
    width: 258px;
    height: 266px;
    border-radius: 3px;
    background: #FFFFFF;
    /* border: 1px solid; */
    text-align: center;

}

.support-lorem {
    margin-top: 20px;
    font-family: Poppins;
    font-size: 11px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;

}

.support-learning {
    margin-top: 10px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.03em;
    text-align: center;

}

.support-faq {
    margin-top: 5px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.03em;
    text-align: center;
}

.support-support {
    margin-top: 10px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.03em;
    text-align: center;
}


.support-learning-img {
    margin-top: 40px;
}

.support-faq-img {
    margin-top: 40px;
}

.support-support-img {
    margin-top: 40px;
}

.support-learn-with-us-parent1 {
    /* border: 1px solid; */
    width: 258px;
    height: 42px;
    border-radius: 3px;
    background: #2747DD;
    margin-top: 48.5px;

}

.support-learn-with-us-child1 {
    color: #FFFFFF;
    text-decoration: none;
    line-height: 40px;
    padding-right: 140px;
    font-family: Poppins;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.03em;


}

.support-explore-faq-us-parent2 {
    /* border: 1px solid; */
    width: 258px;
    height: 42px;
    border-radius: 3px;
    background: #2747DD;
    margin-top: 48.5px;

}

.support-explore-faq-us-child2 {
    line-height: 40px;
    padding-right: 140px;
    color: #FFFFFF;
    text-decoration: none;
    font-family: Poppins;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.03em;

}

.support-support-us-parent3 {
    /* border: 1px solid; */
    width: 258px;
    height: 42px;
    border-radius: 3px;
    background: #2747DD;
    margin-top: 48.5px;

}

.support-support-us-child3 {
    line-height: 40px;
    padding-right: 170px;
    color: #FFFFFF;
    text-decoration: none;
    font-family: Poppins;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.03em;
}

@media only screen and (max-width: 1400px) {
    .support-child2 {
        max-width: 100%;
    }
}

@media only screen and (max-width: 1200px) {
    .support-child2 {
        flex-direction: row;
        height: auto;
        margin: 0;
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 50px;
    }

    .support-child2-section1, .support-child2-section2, .support-child2-section3 {
        height: auto;
    }
}

@media only screen and (max-width: 480px) {
    .support-child2 {
        flex-direction: column;
        height: auto;
        margin: 0;
        width: 100%;
        align-items: center;
        gap: 37.57px;
        margin-top: 69px;
        margin-bottom: 91px;
    }

    .support-child2-section1, .support-child2-section2, .support-child2-section3 {
        height: auto;
    }
}
.ml-5 {
  margin-left: 5px;
}

.leaderboard-container {
  padding-left: 0px;
  padding-right: 49px;
}

.leaderboardrankings {
  color: #000;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.32px;
  margin-top: 30px;
  margin-left: 28px;
  margin-bottom: 0;
  line-height: 33px;
  position: relative;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 13px;
}

.leaderboardrankings-btm-line {
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -17px;
  background: #2747dd;
  height: 3px;
}

.leaderboard-horizontal-line {
  width: auto;
  height: 1px;
  background: rgba(178, 178, 178, 0.7);
  border: none;
  margin-left: 9px;
  margin-top: 17px;
  margin-bottom: 21px;
}

.learnmoreaboutleaderboard {
  color: #2747dd;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.84px;
  text-decoration: underline;
  margin-left: 0px;
}

.leaderboard-parent-pages {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 19px;
  width: 100%;
}

.leaderboard-table-container {
  width: 100%;
  overflow-x: auto; /* Add horizontal scroll */
}

.leaderboard-table {
  width: 130%;
  border-radius: 3px;
  border-spacing: 0px;
  border-collapse: collapse;
  padding: 0;
  margin-top: 0px;
  margin-bottom: 94px;
  white-space: nowrap; /* Prevent wrapping of table cells */
  background-color: #FFFFFF;
  ; 
}


.leaderboard-table-row {
  border-bottom: 1px solid #dddddd;
}

.leaderboard-table-heading {
  font-family: poppins;
  background: #f3f6ff;
  height: 51px;
  border-radius: 3px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}

.leaderboard-table-navitem {
  color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.84px;
  background-color: #FFFFFF;
  cursor: pointer;
}

.leaderboard-table-data {
  text-align: center;
  padding-top: 13px;
  padding-bottom: 13px;
  /* color: rgba(0, 0, 0, 0.7); */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.96px;
}

.level-icon {
  position: relative;
  top: 4px;
}

.yellowcolortable {
  color: #e7af1d;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.96px;
  text-align: center;
  padding-left: 30px;
}

.greencolortable {
  color: #2acd1c;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.96px;
  text-align: center;
}

.redcolortable {
  color: #f82929;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.96px;
  text-align: center;
}

.bluecolortable {
  color: #2747dd;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.96px;
}

.leaderboard-parent-pages {
  color: rgba(0, 0, 0, 0.7);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding: 36px 0px;
}

.profile-img {
  width: 29px;
  height: 29px;
  border-radius: 3px;
  margin-right: 8px;
  position: relative;
  padding-left: 40px;
  /* top: 5px; */
}

.headericon {
  margin-left: 5px;
}

.market_news_item_icon {
  margin-left: 5px;
}

.level-icon {
  margin-right: 8px;
}

.leaderboard-child1-pages {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  gap: 46px;
}

.leaderboard-prev-next-button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 25px;
  margin-top: 10px;
  margin-right: 45px;
}

.leaderboard-pages2 {
    margin-right: 45px;
    margin-top: 10px;
  
}

.leaderboard-btn1 {
  border: none;
  background: transparent;
  padding: 0px;
  margin: 0px;
}

.leaderboard-btn2 {
  border: none;
  background: transparent;
  padding: 0px;
  margin: 0px;
}

.leaderboard-pages1 {
  padding-left: 0px;
}

.leaderboard-btn3 {
  position: relative;
  top: -35px;
  right: 8px;
  border: none;
  color: #000000b2;
  width: 9px;
  height: 4.5px;
  background: none;
  cursor: pointer;
}

.leaderboard-btn4 {
  position: relative;
  top: -70px;
  right: 8px;
  border: none;
  background: none;
  width: 9px;
  color: #000000b2;
  cursor: pointer;
  height: 4.5px;
}

.leaderboard-nav {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.06em;
  text-align: left;
  margin: 0;
  padding: 0;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

@media only screen and (max-width: 1050px) {
  .leaderboard-table-container {
    width: 100%;
    overflow: scroll;
  }

  .leaderboard-table {
    width: 1150px;
    overflow: scroll;
  }
}

@media only screen and (max-width: 1050px) {
  .leaderboard-table-container {
    width: 100%;
    overflow: scroll;
  }

  .leaderboard-table {
    width: 1150px;
    overflow: scroll;
  }
}

@media only screen and (max-width: 800px) {
  .leaderboard-container {
    width: calc(100vw - 2rem);
    padding: 0px;
  }
}

@media only screen and (max-width: 450px) {
  .leaderboard-container {
    padding: 0px;
  }

  .leaderboard-table-container {
    box-sizing: border-box;
  }

  .learnmoreaboutleaderboard {
    margin-left: 11px;
  }

  .leaderboard-pages1 {
    padding-left: 11px;
  }

  .leaderboard-pages2 {
    margin-right: 0px;
  }

  .leaderboardrankings {
    margin-left: 11px;
  }
}

.leaderboard-btn1[disabled] {
  background-color: transparent;
}

.leaderboard-btn2[disabled] {
  background-color: transparent;
}
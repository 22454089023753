.learn-more-about-leaderboard-image-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 306px;
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0.03em;
    text-align: left;
    color: #ffffff;
    text-shadow: 0px 4px 4px #00000040;
    background: url("../../assets/images/imgtxtbanner.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.learn-more-about-leaderboard-level-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    background: #f3f6ff;
    padding: 74px;
}

.learn-more-about-leaderboard-level {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    border-bottom: 1px solid #b2b2b2b2;
    padding: 49px 0px;
    width: 100%;
}


.learn-more-about-leaderboard-level-part {
    width: 100%;
}


.learn-more-about-leaderboard-level-part-title {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.03em;
    text-align: left;
    color: #000000;
}

.learn-more-about-leaderboard-level-part-description {
    width: 100%;
    margin-left: 0px;
    padding: 0px;
    margin-top: 21px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.03em;
    text-align: left;
    color: #000000b2;
}


@media only screen and (max-width: 1380px) {
    .learn-more-about-leaderboard-level-inner .learn-more-about-leaderboard-level-part {
        width: 100%;
    }
}

@media only screen and (max-width: 720px) {
    .learn-more-about-leaderboard-level .learn-more-about-leaderboard-level-inner {
        display: flex;
        flex-direction: column;
    }

    .learn-more-about-leaderboard-level-inner .learn-more-about-leaderboard-level-part:first-child {
        align-items: center;
    }
}

@media only screen and (max-width: 450px) {
    .learn-more-about-leaderboard-image-text {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0.03em;
        color: #ffffff;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        height: 259px;
    }

    .learn-more-about-leaderboard-level-container {
        padding-top: 26px;
        padding-left: 30px;
        padding-right: 14px;
        padding-bottom: 29px;
    }

    .learn-more-about-leaderboard-level {
        padding-top: 0px;
    }

    .learn-more-about-leaderboard-level-inner .learn-more-about-leaderboard-level-part:first-child {
        margin-left: 2px;
        gap: 12px;
    }

    .learn-more-about-leaderboard-img svg,
    .learn-more-about-leaderboard-img {
        width: 36px;
        height: 36px;
    }

    .learn-more-about-leaderboard-level-name {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 0.03em;
        color: #000000;
    }

    .learn-more-about-leaderboard-level:not(:first-child) {
        margin-top: 21px;
    }

    .learn-more-about-leaderboard-level .learn-more-about-leaderboard-level-inner {
        grid-row-gap: 0px;
        gap: 33px;
    }

    .learn-more-about-leaderboard-level-part-description {
        margin-top: 10px;
    }

    .learn-more-about-leaderboard-level-part-description-list {
        margin-top: 15px;
    }

    .learn-more-about-leaderboard-level {
        padding-bottom: 39px;
    }
    .learn-more-about-leaderboard-level-part-description-list {
        padding-bottom: 0px;
        margin-bottom: 0px;
    }

    .learn-more-about-leaderboard-level-inner .learn-more-about-leaderboard-level-part:nth-child(3) {
        display: none;
    }
}